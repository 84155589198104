import React from 'react';
import styled from 'styled-components';
import theme from '../theme';

const Footer = class extends React.Component {
	getYear() {
		return new Date().getFullYear();
	}
	Rand() {
		const min = 1;
		const max = 100;
		const rand = min + Math.floor(Math.random() * (max - min));
		return rand
	}
	render() {
		return (
			<Container>
				<footer className="footer">
					<div className="column is-4">{this.getYear()+1}</div>
					<div className="column is-4">Ответа не последовало.</div>
					<div className="column is-4">стр. {this.Rand()}</div>
				</footer>
			</Container>	
		)
	}
}

export default Footer

const Container = styled.div`
	padding:var(--spacing-6) var(--spacing-8);
    margin-top: var(--spacing-4);
    font-family: var(--fontFamily-serif);

	@media screen and (min-width: ${theme.breakpoints.large}) {
		margin-top: var(--spacing-20);
	}
	

    footer {
        display: flex;
		align-content:bottom;
		line-height:100%;
		align-items: center;
  		justify-content: center;

        div {
            flex:1;
            &:nth-child(1) {text-align:left}
            &:nth-child(2) {text-align:center}
            &:nth-child(3) {text-align:right}
        }

    }
`;