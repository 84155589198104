import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

const WritingNotActive = [
	'/roman',
	'/contact',
	'/subscribe'
]

export const MenuItems = () => {
	const pathname = useLocation().pathname

	const WritingActive = !WritingNotActive.some(path =>
		pathname.startsWith(path)
	)
	return (
		<ul className="header__menu">
			<li><Link to="/" activeClassName="active" className={WritingActive ? 'active' : ''}>Writing</Link></li>
			<li>
				<Link to="/roman/" activeClassName="active">Who is Roman?</Link>
			</li>
			<li>
				<Link to="/subscribe/" activeClassName="active">Subscribe</Link>
			</li>
			<li><Link to="/contact/" activeClassName="active">Write to me</Link></li>
		</ul> 
	)
}