const theme = {
	breakpoints: {
		xxsmall: '0px',
		xsmall: '361px',
		small: '481px',
		medium: '737px',
		large: '981px',
		xlarge: '1281px'
	},

	size: {
		small: '720px',
		medium: '900px',
		sidebarLayout: '1160px',
		large: '1300px'
	},

	space: [
		'0.25rem',
		'0.5rem',
		'0.75rem',
		'1rem',
		'1.25rem',
		'1.5rem',
		'2rem',
		'3rem',
		'4rem',
		'5rem',
		'5.75rem'
	] as const,

	fontSizes: [
		'0.75rem',
		'1rem',
		'1.25rem',
		'1.5rem',
		'2rem',
		'2.25rem',
		'2.5rem',
		'3rem'
	] as const,

	radii: {
		small: '3px',
		medium: '6px',
		large: '12px',
		extralarge: '24px'
	},

	colors: {
		hoverHighlight: '#E7EEF9',
		purple: '#784D93',
		gray: '#EDEDED', 
		grayLabel: '#9B9B9B'
	},

	fonts: {
		monospace: 'Inconsolata, Monaco, Courier New, monospace',
		sans: '"Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
		serif: '"EB Garamond", Georgia, Cambria, "Times New Roman", Times, serif'
	}
}

export default theme
