import React from 'react'
import styled from 'styled-components'
import theme from '../theme'

interface Props {
  padding?: string
  centered?: boolean
  size?: string
  children?: React.ReactNode
}

export function Block({ padding, centered, size, children }: Props) {
	return (
		<BlockContainer className={padding ? 'padding-' + padding : ''} size={size}>
			<div className={centered ? 'centered' : ''}>{children}</div>
		</BlockContainer>
	)
}

export default Block

const BlockContainer = styled.div<Props>`
  max-width: ${props =>
		props.size ? theme.size[props.size] : theme.size['medium']};
  margin: 0 auto;
  //padding-left: 20px;
  //padding-right: 20px;

  & > div.centered {
    text-align: center;
  }

  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    //padding-left: 40px;
    //padding-right: 40px;
  }

  &.padding-none {
    padding: 0;
  }

  &.padding-small {
    padding-top: 16px;
    padding-bottom: 16px;

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      //padding-top: 40px;
      //padding-bottom: 40px;
    }
  }

  &.padding-medium {
    padding-top: 40px;
    padding-bottom: 40px;

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }

  &.padding-large {
    padding-top: 64px;
    padding-bottom: 64px;

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      padding-top: 92px;
      padding-bottom: 92px;
    }
  }
`
