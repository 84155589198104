import React from 'react'
import styled from 'styled-components';
import { MenuItems } from './MenuItems'
import theme from '../theme';

const Header = () => {

	return (
		<HeaderContainer>
			<header>
				<div className="header_menu">
					<div className="menu-items"><MenuItems /></div>
				</div>
			</header>
		</HeaderContainer>
	);
};
export default Header;

const HeaderContainer = styled.div`
  padding-top:8px;
  font-weight: var(--fontWeight-semibold);
  margin-bottom: var(--spacing-10);
  margin-top: var(--spacing-2);
  font-family: var(--fontFamily-sans);
  border-bottom:2px black solid;

  @media screen and (min-width: ${theme.breakpoints.large}) {
    margin-top: var(--spacing-4);
    position: sticky;
    top: 0;
    z-index:2;
    backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.8);
    border-bottom:none;
  }

  .header_menu ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin:0 0 var(--spacing-2);
    user-select:none;

    @media screen and (min-width: ${theme.breakpoints.large}) {
      flex-direction: row;
    }

    li {
      flex: 1;
      font-size:20px;
      text-align: center;
      line-height: 140%;
      margin-bottom: var(--spacing-4);

      @media screen and (min-width: ${theme.breakpoints.large}) {
        margin-bottom: var(--spacing-2);
      }
    }

      a {
        text-decoration: none;
        display:block;

        &:not(.active):hover {
          color:#DA3F3F;
        }

        &.active {
          font-family: var(--fontFamily-serif);
          font-size:24px;
          margin-top:-4px;
          font-weight:500;

          &:hover {
            text-decoration:none;
          }

          &:after, &:before {
            color: rgba(0,0,0,.3);
          }

          &:after {
          content: ")";
          padding-left:16px;
          }

          &:before {
            content: "(";
            padding-right:16px;
          }
        }
      }
    }
  }
`