import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Seo from './Seo';

  
const Layout = ({ location, title, children }) => {
	const rootPath = `/`;
	const isRootPath = location.pathname === rootPath;

	return (
		<>
			<div className="global-wrapper" data-is-root-path={isRootPath}>
				<Seo title={title} />
				<Header/>
				<main>{children}</main>
			</div>
			<Footer/>
		</>
	);
};

export default Layout;
